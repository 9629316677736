<template>
    <div>
        <main class="container-fluid">
            <div class="row mx-md-4">
				<!-- Breadcrumb -->
				<div class="col-md-12 mb-4">
					<div class="card shadow border-0 round">
						<div class="card-body px-2 py-1">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb bg-transparent mb-0">
									<li class="breadcrumb-item text-info"><router-link to="/" class=" text-decoration-none">Home</router-link></li>
									<!-- <li class="breadcrumb-item text-secondary">
										<router-link to="/member/profile" class=" text-decoration-none">Member</router-link>
									</li> -->
									<li class="breadcrumb-item active" aria-current="page">Bandingkan Produk</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
				
				<div class="col-md-12 mb-4">
					<div class="card shadow border-primary round">
						<div class="card-body p-4">
							<h4 class="text-center my-2"><i class="fa fa-sliders-h mr-3"></i>Bandingkan Produk</h4>
							<hr />
							<div class="text-center my-3" v-if="isRefresh">
    						  	<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    						</div>
							<div class="table-responsive-md" v-else>
								<table class="table table-hover table-bordered text-center">
									<thead>
										<tr>
											<th scope="col" width="15%">Gambar Produk</th>
											<th scope="col" width="15%">Tentang Produk</th>
											<th scope="col" width="15%">Brand</th>
											<th scope="col" width="15%">Harga</th>
											<th scope="col" width="15%">Rating</th>
											<th scope="col" width="15%">Jumlah Stok</th>
											<th scope="col" width="15%">Aksi</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(product, index) in products" :key="index">
											<td class="align-middle">
												<router-link :to="'/product-detail/'+product.product.prod_id+'-'+product.product.prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
													<img v-lazy="productImage(product.product.prod_image)" @error="imageLoadError" class="card-img-top w-100" :alt="product.product.prod_image" />
												</router-link>
											</td>
											<td class="align-middle">
												<router-link :to="'/product-detail/'+product.product.prod_id+'-'+product.product.prod_name.toLowerCase()" data-dismiss="modal" class="text-decoration-none">
													<h6 class="card-text text-secondary mb-0">{{ product.product.prod_name | capitalize }}</h6>
													<p class="text-muted mb-1">{{ product.product.prod_summary | str_limit(50) }}</p>
												</router-link>
											</td>
											<td class="align-middle">{{ product.product.brand_name }}</td>
											<td class="align-middle">
												<!-- <div v-if="product.product.prod_discount && product.product.prod_discount != 0">
													<h6 class="text-success mb-0 pr-2"><strong>{{ discountedPrice(product.product.prod_price, product.product.prod_discount) | formatCurrency('IDR') }}</strong></h6>
													<p class="mb-0 d-flex align-items-center justify-content-center">
														<small class="bg-danger text-white round px-2">{{ product.product.prod_discount }}%</small> 
														<strike class="text-muted pl-1">{{ product.product.prod_price | formatCurrency('IDR') }}</strike>
													</p>
												</div>
												<h6 class="text-success mb-0" v-else><strong>{{ product.product.prod_price | formatCurrency('IDR') }}</strong></h6> -->
												<h6 class="text-success mb-0"><strong>-</strong></h6>
											</td>
											<td class="align-middle">
												<div class="d-inline-flex justify-content-center align-items-center" v-for="index2 in 5" :key="index2">
													<i class="fa fa-star text-warning" v-if="index2 <= product.product.prod_rating"></i>
													<div class="fa-stack" style="height: 16px; line-height: 20px; width: 18px;" v-else-if="index2 - 0.5 <= product.product.prod_rating">
														<i class="fa-stack-1x fa fa-star text-secondary"></i>						
														<i class="fa-stack-1x fa fa-star-half text-warning"></i>						
													</div>
													<i class="fa fa-star text-secondary" v-else></i>
												</div>
												<br/>
												<p class="mb-0">({{ product.product.prod_rating }})</p>
											</td>
											<td class="align-middle text-success" v-if="product.product.prod_stock">{{ product.product.prod_stock }} Items</td>
											<td class="align-middle text-danger" v-else>0 Item</td>
											<td class="align-middle">
												<button class="btn btn-outline-danger w-100" @click="remove_localstorage(product.urutan)"><i class="fa fa-trash"></i></button>
											</td>
										</tr>
										<tr v-for="index in 3" :key="index">
											<td colspan="7" v-if="products.length < index"><router-link to="/product-list?type=product&q=Semua Produk" class="btn btn-outline-info w-50"><i class="fa fa-search"></i> Cari produk untuk dibandingkan</router-link></td>
										</tr>
									</tbody>
								</table>
								<p class="ml-3 mb-0 text-info"><i class="fa fa-info-circle fa-lg"></i> Maksimal 3 produk untuk dibandingkan.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
        </main>
    </div>
</template>

<script>

export default {
	data () {
 		return {
			products: [],
			isRefresh: false,
 		}
	},
	methods: {
		get_localstorage() {
			this.isRefresh = true
			this.products = []
			
			if(!!localStorage.getItem('prod_compare_0')) {
				this.products.push({
					'product': JSON.parse(localStorage.getItem('prod_compare_0')),
					'urutan': 0
				})
			}
			if(!!localStorage.getItem('prod_compare_1')) {
				this.products.push({
					'product': JSON.parse(localStorage.getItem('prod_compare_1')),
					'urutan': 1
				})
			}
			if(!!localStorage.getItem('prod_compare_2')) {
				this.products.push({
					'product': JSON.parse(localStorage.getItem('prod_compare_2')),
					'urutan': 2
				})
			}
			this.isRefresh = false
		},
		remove_localstorage(index) {
			localStorage.removeItem('prod_compare_'+ index)
			this.get_localstorage()
		}
	},
    created () {
		$(document).scrollTop(0)
		this.get_localstorage()
	}
}
</script>